import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import LinearProgress from '@material-ui/core/LinearProgress';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { orange } from '@material-ui/core/colors';

import Routes from 'app/routes';
import Layout from 'app/hoc/Layout/Layout';
import { userRoleSelector } from 'app/store/auth/selector';
import { resetToast } from 'app/store/snackbar/actions';

function Alert(props) {
	return <MuiAlert elevation={ 6 } variant="filled" { ...props } />;
}

function Loading({ error }) {
  if (error) {
    return (<h2 className="text-no-data"> Oh nooess! Something went wrong. Try re-loading!</h2>)
  } else {
    return <LinearProgress color="secondary" />;
  }
}

const App = ({ role, snackbar, handleCloseSnackbar }) => {
	const { open, title, severity, timeout } = snackbar;

	// const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () =>
      createMuiTheme({
				typography: {
					fontFamily: 'Raleway, sans-serif',
				},
        palette: {
          type: 'light',
					primary: {
						main: '#292e32',
					},
					secondary: {
						main: orange[ 500 ],
					},
				}
      }),
    [],
  );

	return (
		<ThemeProvider theme={ theme }>
			<CssBaseline/>
			<Layout role={ role }>
				
				<Suspense fallback={ <Loading /> }><Routes role={ role } /></Suspense>
				{open && <Snackbar open={ open } autoHideDuration={ timeout } onClose={ handleCloseSnackbar } anchorOrigin={ { vertical: 'top', horizontal: 'center' } }>
					<Alert onClose={ handleCloseSnackbar } severity={ severity }>
						{title}
					</Alert>
				</Snackbar>}
			</Layout>
		</ThemeProvider>
	);
}

App.propTypes = {
	role: PropTypes.string.isRequired,
	snackbar: PropTypes.shape({
		open: PropTypes.bool,
		timeout: PropTypes.number,
		severity: PropTypes.string,
		title: PropTypes.string,
	}),
	handleCloseSnackbar: PropTypes.func.isRequired,
}

App.defaultProps = {
	snackbar: {
		open: false,
		timeout: 0,
		severity: 'info',
		title: '',
	}
}

const mapStateToProps = state => {
	return {
		role: userRoleSelector(state),
		snackbar: state.appSnackbar
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		handleCloseSnackbar: () => dispatch(resetToast()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
