import routePathNames from './routePathNames';

const navigations = [
	
	{ link: routePathNames.MOVIES, exact: false, title: 'Movies', granted: [ 'ADMIN', 'GUEST' ] },
	{ link: routePathNames.SHORT_FILMS, exact: false, title: 'Short Films', granted: [ 'ADMIN', 'GUEST' ] },
	{ link: routePathNames.EVENTS, exact: false, title: 'Events', granted: [ 'ADMIN', 'GUEST' ] },
	{ link: routePathNames.ABOUT_US, exact: false, title: 'About Us', granted: [ 'ADMIN', 'GUEST' ] },
];

export default {
	ADMIN: navigations.filter((item) => item.granted.includes('ADMIN')),
	GUEST: navigations.filter((item) => item.granted.includes('GUEST')),
};
