import React from 'react';
import PropTypes from 'prop-types';

import AppHeader from 'app/shared/components/AppHeader';
import AppFooter from 'app/shared/components/AppFooter';
import navigationsItems from 'app/constants/navigation';

const Layout = ({ role, children }) => {

	return (
		<>
			<AppHeader sections={ navigationsItems[ role ] } title="Saraswati Films"/>
			<main>{children}</main>
			<AppFooter />
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	role: PropTypes.string.isRequired
};

export default Layout;
