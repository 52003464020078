import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';

import NAVIGATION_LIST from 'app/constants/navigation';

const NavigationItems = ({ classes, goTo, user,currentRoute }) => {
	const { role } = user;

	const navigations = NAVIGATION_LIST[ role ].map((navItem) => {
		return (
			<Button
				className={ classes }
				color="inherit"
				key={ navItem.title }
				onClick={ () => goTo(navItem.link) }
				data-test="nav-items"
				{ ...(currentRoute === navItem.link && { color: 'secondary' }) }
			>
				{navItem.title}
			</Button>
		);
	});

	return (
		<>
			{navigations}
		</>
	);
};

NavigationItems.propTypes = {
	isLoggedIn: PropTypes.bool,
	classes: PropTypes.string,
	currentRoute: PropTypes.string,
	user: PropTypes.shape({
		name: PropTypes.string,
		role: PropTypes.string,
		imageUrl: PropTypes.string
	}),
	goTo: PropTypes.func.isRequired,
};

NavigationItems.defaultProps = {
	isLoggedIn: false,
	classes: '',
	currentRoute: '',
	user: {
		name: '',
		role: 'GUEST',
		imageUrl: ''
	},
};

export default React.memo(NavigationItems);
