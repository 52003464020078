export const updateObject = (oldObject, updatedProperties) => {
  return {
      ...oldObject,
      ...updatedProperties
  };
};

export const randomHash = () => {
	return String(
		Date.now() +
		'-' +
		Math.random()
			.toString(36)
			.substr(2, 9),
	);
};

export const classesString = (obj) => {
	return Object.keys(obj).filter(item => obj[ item ] === true).join(' ')
}

export const stripHTML = (str) => str.replace(/<[^>]+>/g, '');
