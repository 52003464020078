export const APP_NAME = 'Saraswatifilms'
export const CONTACT_EMAIL = 'manish@saraswatifilms.org'
export const APP_LINK = 'http://localhost:3000'

export const CATEGORIES_SHORT_FILMS = [
  'Devotional', 'Mahabharata', 'Conspiracy', 'Hinduism', 'Saturn', 'Media Weediya'
]

export const SOCIAL_FOLLOWERS = {
  FACEBOOK: 400,
  TWITTER: 3000
}
export const SOCIAL_LINK = {
  FACEBOOK: 'https://www.facebook.com/manish.pandit.5209',
  TWITTER: 'https://twitter.com/mmpandit'
}
