const INITIAL_STATE = {
	role: 'GUEST',
	error: null,
	loading: false,
};

const reducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		default:
			return state;
	}
};

export default reducer;
