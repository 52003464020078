import React from 'react';

import routePathNames from 'app/constants/routePathNames';

const Movies = React.lazy(() => {
  return import('../components/Movies')
});

const MovieDetails = React.lazy(() => {
  return import('../components/Movies/Details')
});

const ShortFilms = React.lazy(() => {
  return import('../components/ShortFilms')
});

const Events = React.lazy(() => {
  return import('../components/Events')
});

const AboutUs = React.lazy(() => {
  return import('../components/About')
}); 

const ErrorPage = React.lazy(() => {
  return import('app/shared/components/ErrorPage')
});

const routesList = [
	{
		path: routePathNames.MOVIES_DETAIL,
		exact: false,
		component: MovieDetails,
		granted: [ 'ADMIN', 'GUEST' ],
	},
	{
		path: routePathNames.MOVIES,
		exact: false,
		component: Movies,
		granted: [ 'ADMIN', 'GUEST' ],
	},
	{
		path: routePathNames.SHORT_FILMS,
		exact: false,
		component: ShortFilms,
		granted: [ 'ADMIN', 'GUEST' ],
	},
	{
		path: routePathNames.EVENTS,
		exact: false,
		component: Events,
		granted: [ 'ADMIN', 'GUEST' ],
	},
	{
		path: routePathNames.ABOUT_US,
		exact: false,
		component: AboutUs,
		granted: [ 'ADMIN', 'GUEST' ],
	},
	{
		path: routePathNames.ERROR_PAGE,
		exact: false,
		component: ErrorPage,
		granted: [ 'ADMIN', 'GUEST' ],
	},
	{
		path: '/',
		exact: true,
		component: Movies,
		granted: [ 'ADMIN', 'GUEST' ],
	},
	{
		path: routePathNames.REST_ALL,
		exact: false,
		component: ErrorPage,
		granted: [ 'ADMIN', 'GUEST' ],
	},
];

export default {
	ADMIN: routesList.filter((item) => item.granted.includes('ADMIN')),
	GUEST: routesList.filter((item) => item.granted.includes('GUEST')),
};
