import * as actionTypes from './types';

export const setToast = (toast) => {
	return {
		type: actionTypes.SET_APP_TOAST,
		toast,
	};
};

export const resetToast = () => {
	return {
		type: actionTypes.RESET_APP_TOAST,
	};
};
