import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import NavigationItems from 'app/shared/components/AppHeader/NavigationItems';
import SideNav from 'app/shared/components/SideNav'
import './AppHeader.scss';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		flexShrink: 1,
		width: '100%',
	},
	grow: {
		flexGrow: 1,
	},
	navitems: {
		[ theme.breakpoints.down('md') ]: {
      display: 'none'
    },
	},
	sideNav: {
		display: 'none',
		[ theme.breakpoints.down('md') ]: {
      display: 'block'
    },
	}
}));

const Header = ({ history, title, location }) => {
	const classes = useStyles();

	const goTo = (path) => {
		history.push(path);
	};

	return (
		<div className={ classes.root }>
			<AppBar position="static" className="app-header">
				<Toolbar>
					<Typography variant="subtitle1" color="inherit" className={ classes.grow }>
						<Button onClick={ () => goTo('/') } color="secondary" className="capitalize">
							<img src="/SaraswatiFilms-Logo.png" alt="Saraswati Films" height="48px"/>
							<span className="app-title ml--2">
								{title}
							</span>
						</Button>
					</Typography>
					<div className={ classes.navitems }>
						<NavigationItems
							goTo={ goTo }
							classes="capitalize"
							currentRoute={ location.pathname }
					/>
					</div>
					<SideNav goTo={ goTo }
						currentRoute={ location.pathname } className={ classes.sideNav }/>
				</Toolbar>
			</AppBar>
		</div>
	);
};

Header.propTypes = {
	history: PropTypes.any,
	location: PropTypes.objectOf(PropTypes.any).isRequired,
	title: PropTypes.string
};

Header.defaultProps = {
	history: null,
	title: 'Saraswati Films'
};

export default withRouter(Header)
