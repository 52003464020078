export default {
	MOVIES: '/movies',
	MOVIES_DETAIL: '/movies/:id',
	SHORT_FILMS: '/short-films',
	EVENTS: '/events',
	ABOUT_US: '/about-us',
	ERROR_PAGE: '/404',
	LANDING: '/',
	REST_ALL: '*',
};
