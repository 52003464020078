import React, { useState } from 'react';
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import NAVIGATION_LIST from 'app/constants/navigation';

const useStyles = makeStyles({
  list: {
    width: 250,
  }
});

const SideNav = ({ currentRoute, goTo, user, className }) => {
  const classes = useStyles();

  const [ drawer, setDrawer ] = useState(false)
	const { role } = user;
	
	const handleNavigation = (navigateTo) => {
		goTo(navigateTo);
		setDrawer(false)
	}

	const navigations = NAVIGATION_LIST[ role ].map((navItem) => {
		return (
			<ListItem button key={ navItem.title } onClick={ () =>  handleNavigation(navItem.link) } selected={ currentRoute === navItem.link } >
				<ListItemText primary={ navItem.title } />
			</ListItem>
		);
  });

  return (
	<div className={ className }>
		<React.Fragment >
			<IconButton className={ classes.iconButton } onClick={ () => setDrawer(true) } color="inherit">
				<MenuIcon />
			</IconButton>
			<Drawer anchor="left" open={ drawer } onClose={ () => setDrawer(false)  }>
				<List className={ classes.list }>
					{navigations}
				</List>
			</Drawer>
		</React.Fragment>
	</div>
  );
}

SideNav.propTypes = {
	isLoggedIn: PropTypes.bool,
	classes: PropTypes.string,
	currentRoute: PropTypes.string,
	user: PropTypes.shape({
		name: PropTypes.string,
		role: PropTypes.string,
		imageUrl: PropTypes.string
	}),
	className: PropTypes.string,
	goTo: PropTypes.func.isRequired,
};

SideNav.defaultProps = {
	isLoggedIn: false,
	classes: '',
	currentRoute: '',
	user: {
		name: '',
		role: 'GUEST',
		imageUrl: ''
	},
	className: ''
};

export default SideNav
