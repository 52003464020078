import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { APP_NAME, CONTACT_EMAIL, APP_LINK } from 'app/constants/app'

function Copyright() {
  return (
	<Typography variant="body2" color="textSecondary" align="center">
		{'Copyright © '}
		<Link color="inherit" href={ APP_LINK }>
			{APP_NAME}
		</Link>{' '}
		{new Date().getFullYear()}
		{'.'}
	</Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
		// padding: theme.spacing(6, 0),
		height: '50px'
  },
}));

export default function AppFooter(props) {
  const classes = useStyles();
  // const { title } = props;

  return (
	<footer className={ classes.footer }>
		<Container maxWidth="lg">
			{/* <Typography variant="h6" align="center" gutterBottom>
					{title}
				</Typography> */}
			<Typography variant="subtitle1" align="center" color="textSecondary" component="p">
				{CONTACT_EMAIL}
			</Typography>
			<Copyright />
		</Container>
	</footer>
  );
}

AppFooter.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};

AppFooter.defaultProps = {
  description: 'Des',
  title: 'totle',
};
