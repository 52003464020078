import React from 'react';
import router from './routes';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

const Routes = ({ role }) => {
	// Has role, fetch routes based on Role
	const routes = router[ role ].map((routeItem) => (
		<Route path={ routeItem.path } exact={ routeItem.exact } component={ routeItem.component } key={ routeItem } />
	));

	return (
		<Switch>
			{routes}
		</Switch>
	);
};

Routes.propTypes = {
	role: PropTypes.string,
};

Routes.defaultProps = {
	role: 'GUEST',
};

export default Routes;
