import * as actionTypes from './types';
import { updateObject } from 'app/shared/utils/helpers';

const initialState = {
	open: false,
	timeout: 0,
	severity: 'info',
	title: '',
};

const setToast = (state, toast) => {
	return updateObject(state, { ...toast });
};

const resetToast = (state) => {
	return updateObject(state, { ...initialState });
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_APP_TOAST:
			return setToast(state, action.toast);
		case actionTypes.RESET_APP_TOAST:
			return resetToast(state);
		default:
			return state;
	}
};

export default reducer;
